export const SidebarData = [
    {
        title: 'Home',
        path: '/',
        className: 'nav-text',
    },
    {
        title: 'Microbots',
        path: '/Microbots',
        className: 'nav-text',
    },
    {
        title: 'Projects',
        path: '/Projects',
        className: 'nav-text',
    },
    {
        title: 'Meet the Team',
        path: '/MeetTheTeam',
        className: 'nav-text',
    },
    // {
    //     title: 'Donate',
    //     path: '/Donate',
    //     className: 'nav-text',
    // },
    // {
    //     title: 'Shop',
    //     path: '/Shop',
    //     className: 'nav-text',
    // },
];
